import React, { Suspense, lazy } from "react";
import { render } from "react-dom";
import "./index.scss";

const rootElement = document.querySelector("#root");
const App = lazy(() => import("./App"));

render(
  <Suspense fallback={<p>Loading ...</p>}>
    <App />
  </Suspense>,
  rootElement
);
